/* @import url('https://rsms.me/inter/inter-ui.css'); */
/* html { font-family: 'Inter UI', sans-serif; } */
/* @supports (font-variation-settings: normal) {
  html { font-family: 'Inter UI var alt', sans-serif; }
} */

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

button:focus {outline:0;}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
  font-size: 15px;
  line-height: 20px;
  font-family: 'SF Pro Display','-apple-system','BlinkMacSystemFont','San Francisco','Helvetica Neue','Helvetica','Ubuntu','Roboto','Noto','Segoe UI','Arial',sans-serif;
  overflow: hidden;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

canvas {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
}

svg {
  background-color: black;
}

body, h1, h2, h3, .button {
  font-size: 15px;
  margin: 0;
}

.button {  
  background-color: rgba(0,0,0,0.5);
  color: white;
  padding: 4px  7px;
  line-height: 100%;
  border-width: 1px;
  border-color: white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  cursor: pointer;  
  border-right: none;
}

.button:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.button:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border: 1px solid white;
}

.button:only-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.button-group {
  display: inline;
  margin-right: 5px;
}

@media (hover: hover) {
  .button { 
    padding: 4px 8px;
  }
  .button:hover {
    text-decoration: underline;    
  }
}

.button:active {
  background-color: white;
  color: black;
  border-color: white;
  text-decoration: none;
  border-color: white;
}

.button[disabled] {
  text-decoration: none;
  cursor: default;
  background-color: white;
  color: black;
}

.image-file-buffer-container {
  position: absolute;
}

.image-file-buffer-container > svg {
  position: absolute;
}

.toolbar {
  text-align: right;
  position: absolute;
  top: 0;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity .25s ease-in-out;
  width: 100%;
  z-index: 200;
  padding: 10px;
}

/* .svg-container {
  position: absolute;
  display: block;
  border: 1px solid rgba(0,0,0,0);
}

.svg-container:hover {
  border: 1px solid white;
} */

#drawings {
  height: 100%;
  right: 0;
  top: 0;
}

#drawings > svg {
  display: block;
  position: absolute;
  background-color: black;
  border: 2px solid rgba(0,0,0,0);
}

#hidden {
  position: absolute;
  top: -5000px;
  left: -5000px;
  width: 100%;
  height: 100%;
}

.toolbar-mobile-hamburger {
  color: white;
}

.main-toolbar {
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.modal-background-toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background-color: rgba(0,0,0,0.5);
}

.modal-content {
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 20px;
  border: 1px solid white;
  box-sizing: border-box;
  
  color: white;
  margin: 20px;
  z-index: 1002;
  hyphens: auto;
}

.about-modal-content {
  max-width: 680px;
  max-height: calc(100% - 100px);
  padding: 20px 10px 20px 20px;
}

.perma-modal-content {
  max-width: 350px;
  max-height: 350px;
  padding: 30px;
}

.modal-content-scroll {
  height: 100%;
  width: 100%;
  overflow:scroll;
  padding-right: 20px;
  box-sizing: border-box;
}

.modal-content a {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid #888;
}

.modal-content a:hover {
  border-bottom-color: white;
}

.perma-image {
  height: calc(100% - 20px);
  padding-bottom: 20px;
}

.perma-modal-content {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
